<template>
	<div>
		<a
			href="https://www.google.com/maps/place/Brooklyn,+NY"
			target="_blank"
		>
			<div class="overflow-hidden"></div>
		</a>
	</div>
</template>

<style scoped>
.overflow-hidden {
	height: 203px;
	background: url(../../assets/map.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@media (min-width: 1024px) {
	.overflow-hidden {
		height: 255px;
	}

}

@media (min-width: 1280px) {
	.overflow-hidden {
		height: 313px;
	}
}
</style>
