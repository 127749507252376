<template>
   <Main>
      <section class="banner pt-28 md:pt-32 lg:pt-32 2xl:pt-48 px-6 xs:px-10">
         <div class="mx-auto w-full max-w-screen-xl pt-6 sm:pt-7 md:pt-0">
            <MainSection />
         </div>
      </section>

      <section class="pt-14 md:pt-20 lg:pt-15 xl:pt-20 px-6 xs:px-10">
         <div class="mx-auto w-full max-w-screen-xl">
            <OurContactInformation />
         </div>
      </section>

      <section class="pt-16 md:pt-20 lg:pt-32 xl:pt-36">
         <Map />
      </section>
   </Main>
</template>
<script>
import Main from "@/components/Main";

import MainSection from "./MainSection";
import OurContactInformation from "./OurContactInformation";
import Map from "./Map";

export default {
   components: {
      Main,
      MainSection,
      OurContactInformation,
      Map,
   },

   mounted() {
      window.onload = () => {
         const el = document.getElementById("loader-container");
         el.classList.add("hidden");
      };
   },
};
</script>
