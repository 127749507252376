<template>
	<div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
		<div class="text-white md:pb-14 lg:pb-14 xl:pb-32">
			<TellUsWhatYouNeed />
		</div>
		<div
			class="
                  w-full
                  relative
                  mt-7
                  md:mt-0
                  "
		>
			<SendUsAMessage />
		</div>
	</div>
</template>

<script>
import TellUsWhatYouNeed from "./TellUsWhatYouNeed";
import SendUsAMessage from "./SendUsAMessage";

export default {
	components: {
		TellUsWhatYouNeed,
		SendUsAMessage,
	},
};
</script>
