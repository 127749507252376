<template>
	<div class="w-full">
	<h1
		class="
                  text-center
                  xl:text-left
                  "
	>
		Tell us <span class="text-red font-bold whitespace-nowrap">what you need</span>
	</h1>
   
	<h3 class="text-center mx-auto xl:mx-0 xl:text-left mt-3 md:mt-5 w-full max-w-lg">
		You can send us a message here, or you can email us directly. We try our best to respond promptly!
	</h3>
	</div>
</template>
